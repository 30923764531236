<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  {{ companyData.companies_id }} - {{ companyData.companies_name }} -> invoices
                </div>
              </div>
            </div>
        </div>
      </div>
        
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Invoice Periods</h1>
        <b-table
            :data="invoicePeriodData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableinvoicePeriodsIsLoading"
            :mobile-cards="true">

            <b-table-column field="wa_invoice_periodes_company_id" label="company_id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_company_id }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_id" label="invoice_periodes_id" width="20" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_id }}
            </b-table-column>            
            <b-table-column field="wa_invoice_periodes_start_date" label="start" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_invoice_periodes_start_date)) }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_end_date" label="end" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_invoice_periodes_end_date)) }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_month" label="month" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_month }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_year" label="year" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_year }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_count_invoices" label="count_invoices" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_count_invoices }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_sum_total" label="sum_total" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_sum_total }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_is_completed" label="is_completed" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_is_completed }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_deleted_at" label="deleted_at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_deleted_at ? DDMMYYYY_HHii(new Date(props.row.wa_invoice_periodes_deleted_at)) : '' }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Invoice Data</h1>
        <b-table
            :data="invoiceData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableinvoicesIsLoading"
            :paginated="invoiceDataIsPaginated"
            :per-page="invoiceDataIsPaginatedPerPage"
            :current-page.sync="invoiceDataCurrentPage"
            :mobile-cards="true">

            <b-table-column field="invoices_id" label="invoices_id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_id }}
            </b-table-column>
            <b-table-column field="invoices_invoice_period_id" label="invoice period id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_invoice_period_id }}
            </b-table-column>
            <b-table-column field="invoice_items_vacancy_id" label="vacancy id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/company/${companyId}/vacancy/${props.row.invoice_items_vacancy_id}`' target='_blank' class="highlight">
                {{ props.row.invoice_items_vacancy_id }}
              </a>
            </b-table-column>
            <b-table-column field="invoices_id" label="invoices id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_id }}
            </b-table-column>
            <b-table-column field="invoices_parent_invoice_id" label="parent invoices id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_parent_invoice_id }}
            </b-table-column>
            <b-table-column field="invoices_credit_invoice_id" label="credit invoice id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_credit_invoice_id }}
            </b-table-column>
            <b-table-column field="invoices_billed_by_user_id" label="billed by userId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              <a :href='`${WEB_ROOT}/company/${companyId}/user/${props.row.invoices_billed_by_user_id}`' target='_blank' class="highlight">
                {{ props.row.invoices_billed_by_user_id }}
              </a>
            </b-table-column>
            
            <b-table-column field="invoice_items_shift_id" label="shift_id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_shift_id }}
            </b-table-column>            
            <b-table-column field="invoice_items_shift_id_correction" label="shift_id correction" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_shift_id_correction }}
            </b-table-column>
            <b-table-column field="invoice_items_type" label="invoice type" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_type }}
            </b-table-column>
            <b-table-column field="invoice_items_invoice_item_type" label="invoice item type" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_invoice_item_type }}
            </b-table-column>
            <b-table-column field="invoice_items_quantity" label="items quantity" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_quantity }}
            </b-table-column>
            <b-table-column field="invoice_items_time_minutes" label="time hours" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ (props.row.invoice_items_time_minutes / 60) }}
            </b-table-column>
            <b-table-column field="invoice_items_time_start" label="time start" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.invoice_items_time_start)) }}
            </b-table-column>
            <b-table-column field="invoice_items_time_end" label="time end" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.invoice_items_time_end)) }}
            </b-table-column>
            <b-table-column field="invoice_items_unit_price" label="unit price" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_unit_price }}
            </b-table-column>
            <b-table-column field="invoice_items_vat_percentage" label="vat %" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_vat_percentage }}
            </b-table-column>
            <b-table-column field="invoice_items_amount" label="items amount" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoice_items_amount }}
            </b-table-column>
            <b-table-column field="invoices_date" label="invoice date" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ DDMMYYYY_HHii(new Date(props.row.invoices_date)) }}
            </b-table-column>
            <b-table-column field="invoices_vat_total" label="invoices vat total" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_vat_total }}
            </b-table-column>
            <b-table-column field="invoices_sub_total" label="invoices sub total" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_sub_total }}
            </b-table-column>
            <b-table-column field="invoices_total" label="invoices total" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_total }}
            </b-table-column>
            <b-table-column field="invoices_deleted_at" label="invoices deleted at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.invoices_deleted_at ? DDMMYYYY_HHii(new Date(props.row.invoices_deleted_at)) : '' }}
            </b-table-column>
            <b-table-column field="wa_invoice_periodes_deleted_at" label="invoices periodes deleted at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_invoice_periodes_deleted_at ? DDMMYYYY_HHii(new Date(props.row.wa_invoice_periodes_deleted_at)) : '' }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>
/* eslint-disable */
import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { DDMMYYYY_HHii } from '@/helpers/dates.js';

import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyId           : null,
          companyData         : null,
          
          invoicePeriodData       : [],          
          tableinvoicePeriodsIsLoading  : false,
          
          invoiceData             : [],          
          tableinvoicesIsLoading  : false,          
          invoiceDataIsPaginated  : true,
          invoiceDataIsPaginatedPerPage: 500,
          invoiceDataCurrentPage  :  1,
          
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.ShowPageLoading(15000)
        this.getData()
      },
      methods: {
        DDMMYYYY_HHii,
        async getData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/invoices`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company user data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.invoiceData = response.data.payload.invoiceData
          this.invoicePeriodData = response.data.payload.invoicePeriodData
          
          this.HidePageLoading()
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
      }
    }
</script>

<style scoped>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
</style>